<template>
    <v-card>
        <loader-comp :loading="loading" />
        <v-card-title class="headline">Verify {{title}}</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
                <p>Please enter OTP from your {{title}}.</p>
                <v-otp-input
                    v-model="otp"
                    label="Enter OTP"
                    required
                    :rules="otpRules"
                    length="6"
                ></v-otp-input>
                <v-btn color="primary" @click="sendOtp">Re-send OTP</v-btn>
            </v-form>
            <v-snackbar v-model="snackbarModal" :timeout="timeout" class="snackbar-modal" :color="snackbarColor">
                <div class="d-flex justify-space-between align-center">
                  {{ snackbarMessageModal }}
                  <v-btn color="white" text @click="snackbarModal = false">Close</v-btn>
                </div>
              </v-snackbar>
        </v-card-text>
        <v-card-actions class="pb-4">            
            <v-spacer></v-spacer>
            <v-btn @click="cancel" color="secondary">Cancel</v-btn>
            <v-btn :disabled="!valid" color="primary" @click="verifyOtp">Verify</v-btn>
        </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import axiosInstance from '@/utils/axiosConfig';
  import LoaderComp from '../shared/loader-comp';

  export default {
    data() {
        return {
            loading: false,
            otp: '',
            valid: false,
            emailId: this.email,
            phoneNumber: this.phone,
            verificationType: this.type,
            snackbarModal: false,
            snackbarMessageModal: '',
            snackbarColor: '',
            timeout: 3000,
            otpRules: [
                v => !!v || 'OTP is required',
                v => /^\d{6}$/.test(v) || 'OTP must be valid',
            ],
        };
    },
    props: {
        email: String,
        type: String, //Email or Phone
        phone: String,
    },
    watch: {
        email(newVal) {
            this.emailId = newVal;
        },
        type(newVal) {
            this.verificationType = newVal;
        },
        phone(newVal) {
            this.phoneNumber = newVal;
        },
    },
    components:{
        LoaderComp
    },
    computed:{
        title(){
            return this.verificationType;
        }
    },
    methods: {
        async verifyOtp() {
            const payload = {
                contact: this.verificationType === 'Email' ? this.emailId.trim() : this.phoneNumber,
                type: this.verificationType,
                otp: this.otp
            };
            try{
                this.loading = true;
                const response = await axiosInstance.post('user/verify-otp', payload);
                this.snackbarMessageModal = response.data.message;//'OTP verified successfully!';
                this.snackbarColor = 'success';
                this.snackbarModal = true;
                this.returnToParent();
            }catch (error) {
                this.snackbarMessageModal = error.response.data.message;// 'Failed to verify OTP. Please try again.';
                this.snackbarColor = 'error';
                this.snackbarModal = true;
                this.loading = false;
            }
        },
        cancel() {
            if(this.verificationType === 'Email'){
                this.resetForm();
                this.$emit('verificationCancel');
            }else{ //For phone
                this.resetForm();
                this.$emit('phoneVerificationCancel');
            }
        },
        returnToParent(){
            if(this.verificationType === 'Email'){
                this.resetForm();
                this.$emit('onVerificationDone');
            }else{ //For phone
                this.resetForm();
                this.$emit('onPhoneVerificationDone');
            }
        },
        async sendOtp() {
            if (!this.validateInput()) {
                this.snackbarMessageModal = 'Please enter a valid email/phone to send OTP.';
                this.snackbarColor = 'error';
                this.snackbarModal = true;
                return;
            }
            const payload = {
                contact: this.verificationType === 'Email' ? this.emailId.trim() : this.phoneNumber.trim(),
                type: this.verificationType
            };
            try{
                this.loading = true;
                const response = await axiosInstance.post('user/send-otp', payload);
                this.snackbarMessageModal = response.data.message;//'OTP sent successfully!';
                this.snackbarColor = 'success';
                this.snackbarModal = true;
                this.otp = '';
                this.loading = false;
            }catch (error) {
                this.snackbarMessageModal = error.response.data.message;//'Failed to verify OTP. Please try again.';
                this.snackbarColor = 'error';
                this.snackbarModal = true;
                this.otp = '';
                this.loading = false;
            }
        },
        validateInput(){
            let result = true;
            if(this.verificationType === 'Email'){
               if(!this.emailId || !/.+@.+\..+/.test(this.emailId)){
                result = false;
               }
            }else{
                if(!this.phoneNumber || !/^\d{10}$/.test(this.phoneNumber)){
                result = false;
               } 
            }
            return result;
        },
        resetForm() {
            this.emailId = '';
            this.otp = '';
            this.loading = false;
            this.$refs.form.reset();
        }
    },
  };
  </script>
  
  <style scoped>
  .headline {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .snackbar-modal {
    position: absolute;
    bottom: 16px;
    right: 16px;
  }
  </style>
  
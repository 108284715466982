<template>
    <v-footer
      color="blue-grey darken-4"
      class="footer"
      padless
    >
      <ad-strip :zip="zip"/>
      <v-container fluid>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="4" class="text-center py-3">
            <span class="white--text footer-text">
              &copy; 2024 Show Us Shows. All rights reserved.
            </span>
          </v-col>
          <v-col cols="12" md="4" class="text-center py-3">
            <v-btn icon class="white--text">
              <v-icon>mdi-facebook</v-icon>
            </v-btn>
            <v-btn icon class="white--text">
              <v-icon>mdi-twitter</v-icon>
            </v-btn>
            <v-btn icon class="white--text">
              <v-icon>mdi-instagram</v-icon>
            </v-btn>
            <v-btn icon class="white--text">
              <v-icon>mdi-linkedin</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" md="4" class="text-center py-3">
            <!-- <span class="white--text footer-text">
                <a href="#" class="white--text" @click.prevent>Privacy Policy</a> |
                <a href="#" class="white--text" @click.prevent>Terms of Service</a>
            </span> -->
            <span class="white--text footer-text">
                <router-link to="/privacy-policy" class="white--text">Privacy Policy</router-link> |
                <router-link to="/terms-of-use" class="white--text">Terms of Use</router-link>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </template>
  
  <script>
    import AdStrip from './ad-strip/ad-strip.vue'; 
  export default {
    name: 'footer-comp',
    components: {    
      AdStrip
    },
    props: {
      zip: {
        type: String,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .footer {
    padding: 0;
    font-size: 14px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .footer-text {
    font-weight: 300;
    letter-spacing: 0.5px;
  }
  
  .v-footer {
    font-size: 15px;
  }
  </style>
  
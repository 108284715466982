import axiosInstance from '@/utils/axiosConfig';

export async function checkLogin() {
  const token = localStorage.getItem('token');
  
  if (!token) {
    return { success: false, message: 'No token provided.' };
  }

  try {
    const response = await axiosInstance.get('user/checklogin', {
      includeAuthToken: true,
    });
    return response.data;
  } catch (error) {
    //console.error('Error during token validation:', error);
    localStorage.removeItem('token');
    return { success: false, message: 'Invalid token.' };
  }
}

export function getToken(){
  return localStorage.getItem('token');
}

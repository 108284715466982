<template>
  <v-container fluid class="ad-strip-container" v-if="repeatedAds.length > 0">
    <div class="scroll-wrapper" @mouseover="pauseScroll" @mouseleave="resumeScroll">
      <div class="scroll-content" :class="{ 'paused': isPaused }">
        <div
          v-for="(ad, index) in repeatedAds"
          :key="index"
          class="ad"
        >
          <a :href="getCorrectedUrl(ad.link)" target="_blank">
            <v-img
              :src="getImage(ad.ad_image)"
              :alt="'ad-' + (index + 1)"
              :height="imageHeight"
              max-width="200"
              contain
            />
          </a>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import axiosInstance from '@/utils/axiosConfig';

export default {
  name: 'ad-strip',
  data() {
    return {
      loading: false,
      ads: [],
      repeatedAds: [],
      isPaused: false,
      imageHeight: "100px", // Default height for ads
    };
  },
  props: {
    zip: {
      type: String,
      required: true,
    },
  },
  watch: {
    zip: {
      immediate: true,
      handler(newZip) {
        this.fetchAds(newZip);
      },
    },
  },
  mounted() {
    //this.fetchAds();
    window.addEventListener("resize", this.repeatAdsToFillWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.repeatAdsToFillWidth);
  },
  methods: {
    pauseScroll() {
      this.isPaused = true;
    },
    resumeScroll() {
      this.isPaused = false;
    },
    getImage(base64Url) {
      return `data:image/png;base64,${base64Url}`;
    },
    getCorrectedUrl(url) {
      if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        return 'http://' + url;
      }
      return url;
    },
    repeatAdsToFillWidth() {
      const screenWidth = window.innerWidth;
      const adWidth = 220; // Approximate width of each ad including padding
      const minAdsToShow = Math.ceil(screenWidth / adWidth);

      // Repeat ads enough to fill the screen width and prevent empty space
      const repeatCount = Math.max(minAdsToShow / this.ads.length, 2); // Ensuring at least 2 full sets
      this.repeatedAds = Array(Math.ceil(repeatCount) * 2) // Multiply by 2 for smooth scrolling
        .fill(this.ads)
        .flat();
    },
    async fetchAds() {
      try {
        const payload = {
          zip: this.zip || localStorage.getItem('zip'),
        };
        const response = await axiosInstance.post('advertisement/ads', payload, {
          includeAuthToken: false,
        });
        this.ads = response.data;
        if (this.ads.length > 0) {
          this.repeatAdsToFillWidth();
        }
      } catch (error) {
        //console.log(error);
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.ad-strip-container {
  background-color: #f9f9f9;
  padding: 10px 0;
  overflow: hidden;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.scroll-wrapper {
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  background: white;
}

.scroll-content {
  display: flex;
  animation: scroll 30s linear infinite; /* Continuous scrolling effect */
}

.scroll-content.paused {
  animation-play-state: paused;
}

.ad {
  display: inline-block;
  padding: 0 10px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Only scroll half of the repeated ads */
  }
}

@media (max-width: 768px) {
  .scroll-wrapper {
    height: auto;
  }

  .ad-strip-container {
    padding: 5px 0;
  }
}
</style>

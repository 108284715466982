<template>  
  <v-dialog v-model="dialog" persistent max-width="400px">
    <loader-comp :loading="loading" />
    <v-card v-if="screen1">
        <v-card-title class="headline">Login</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid">
            <div>
                <v-text-field
                    v-model="email"
                    label="Email"
                    :rules="emailRules"
                    required
                ></v-text-field>
                <v-text-field
                    v-model="password"
                    label="Password"
                    :rules="passwordRules"
                    :type="showPassword ? 'text' : 'password'"
                    required
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="togglePasswordVisibility"
                ></v-text-field>
            </div>            
            </v-form>
            <v-alert v-if="error" type="error">{{ error }}</v-alert>
        </v-card-text>
        <v-card-actions class="pb-4">            
        <v-btn @click="openSignUp" color="primary">Sign Up</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="cancelFun" color="secondary">Cancel</v-btn>
        <v-btn :disabled="!valid" @click="loginFun" color="primary">{{submitText}}</v-btn>            
        </v-card-actions>
    </v-card>
    <div v-else>
        <verify-contact 
          :email="email"
          :type="type" 
          @onVerificationDone="emailVerified" 
          @verificationCancel="emailVerificationCancel"/>
    </div>
  </v-dialog>
</template>

<script>
import axiosInstance from '@/utils/axiosConfig';
import { mapActions } from 'vuex';
import VerifyContact from './verify-contact';
import LoaderComp from '../shared/loader-comp';

export default {
  data() {
    return {
        loading: false,
        dialog: this.openDialog,
        userTypeLocal: this.userType,
        email: '',
        password: "",
        valid: false,
        error: null,
        emailRules: [
            (v) => !!v || "Email is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [(v) => !!v || "Password is required"],
        showPassword: false,
        screen1: true,
        type:'Email',
    };
  },
  props: {
    openDialog: Boolean,
    userType: {
        type: Number,
        default: null,
    },
  },
  watch: {
      openDialog(newVal) {
          this.dialog = newVal;
      },
      userType(newVal) {
          this.userTypeLocal = newVal;
      },
  },
  components:{
    VerifyContact,
    LoaderComp
  },
  computed: {
    title(){
        let titleText = 'Login';
        if(this.userTypeLocal && this.userTypeLocal === 1)
            titleText = 'Subscriber Login';
        else if(this.userTypeLocal && this.userTypeLocal === 2)
            titleText = 'Organizer Login';
        return titleText;
    },
    submitText(){
        return this.screen1 ? 'login' : 'Verify Email';
    }
  },
  methods: {
    ...mapActions(['login']),
      loginFun() {
          this.$refs.form.validate();
          if (this.valid) {
            this.callLogin();
          }
      },
      async callLogin(){
        this.error = null;
        this.loading = true;
        try {
          const payload = {
              email: this.email,
              password: this.password
          }
          const response = await axiosInstance.post('user/login', payload, {
            includeAuthToken: false,
          });
          // Handle the response
          const data = response.data;
          if (data.success) {
              localStorage.setItem("token", data.token);
              localStorage.setItem("email", this.email);
              this.login(); //vuex action
              this.resetForm();
              this.$emit('onLoginDone', true);
          } else {
              this.loading = false;              
              this.screen1 = false; // show OTP screen
              // this.error = data.message;
          }
        } catch (error) {
          if (error.response) {
          // Server responded with a status other than 200 range
            switch (error.response.status) {
              case 400:
                this.error = 'Email and password are required.';
                break;
              case 401:
                this.error = 'Invalid email or password.';
                break;
              case 500:
                this.error = 'Server error. Please try again later.';
                break;
              default:
                this.error = 'An error occurred. Please try again.';
            }
          } else {
            // Something else happened in setting up the request that triggered an Error
            this.error = 'An error occurred. Please try again.';
          }
          this.loading = false;
        }
      },
      emailVerified(){
        this.screen1 = true;
        this.callLogin();
      },
      emailVerificationCancel(){
        this.screen1 = true;
      },
      resetForm() {
          this.email = '';
          this.password = '';
          this.loading = false;
          this.$refs.form.reset();
      },
      openSignUp() {
          this.$emit('openSignUp');
      },
      cancelFun() {
        this.resetForm();
        this.$emit('loginCancel');
      },
      togglePasswordVisibility() {
        this.showPassword = !this.showPassword;
      },
  },
};
</script>

<style scoped>
.headline {
  text-align: center;
}
.text-right {
  text-align: right;
}
</style>

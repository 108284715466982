// store/mutations.js
export default {
  login(state) {
    state.isLoggedIn = true;
    state.userEmail = localStorage.getItem('email');
  },
  logout(state) {
    state.isLoggedIn = false;
    state.userEmail = null;
  },
  setZipCode(state, userZipCode) { // Add mutation to update zip code
    state.userZipCode = userZipCode;
    localStorage.setItem('zip', userZipCode); // Also update localStorage
  },
};

// store/actions.js
export default {
  login({ commit }) {
    commit('login');
  },
  logout({ commit }) {
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    localStorage.removeItem('zip');
    commit('logout');
  },
  updateZipCode({ commit }, userZipCode) { // Add action to update zip code
    commit('setZipCode', userZipCode);
  },
};

<template>
    <div v-if="loading" class="loader-overlay">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="100"
        :width="10"
      ></v-progress-circular>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      loading: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  
  <style scoped>
  .loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 9999;
  }
  </style>
  
import { checkLogin, getToken } from '@/helpers/auth';
// import { format, parse, parseISO } from 'date-fns';
// import { formatInTimeZone, toZonedTime  } from 'date-fns-tz';

export const helperMixin = {
  methods: {
    async checkLogin() {
      const loginStatus = await checkLogin();
      return loginStatus;
    },
    getToken(){
      return getToken();
    },

    // Date related
    // formattedUtcDate12(localDateString) {
    //   // Get the user's local time zone dynamically
    //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    //   // Parse the local date string without timezone info
    //   const localDate = parse(localDateString, 'yyyy-MM-dd HH:mm', new Date());

    //   // Format the local date in UTC based on the user's time zone
    //   const formattedUtcDateTime = formatInTimeZone(localDate, 'UTC', 'yyyy-MM-dd HH:mm:ss', { timeZone: userTimeZone });

    //   return formattedUtcDateTime;
    // },
    formattedUtcDate(utcDateString) {
      // Extract the date and time portion manually
      const datePart = utcDateString.split('T')[0];  // yyyy-MM-dd
      const timePart = utcDateString.split('T')[1].split(':');  // HH:mm:ss

      // Combine the parts we want, excluding seconds
      const formattedDateTime = `${datePart} ${timePart[0]}:${timePart[1]}`;

      return formattedDateTime;
    },
    // formattedLocalDate(utcDateFromDb) {
    //   // Ensure the input date is treated as UTC
    //   const utcDate = new Date(utcDateFromDb); // Create a Date object from the UTC date string
      
    //   // Check if the Date object is valid
    //   if (isNaN(utcDate.getTime())) {
    //       console.error("Invalid UTC Date:", utcDate);
    //       return "Invalid Date"; // Handle invalid date case
    //   }

    //   // Get the user's local time zone dynamically
    //   const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      
    //   // Convert the UTC date to the user's local time zone
    //   const localDate = toZonedTime(utcDate, userTimeZone);
      
    //   // Check if localDate is valid
    //   if (isNaN(localDate.getTime())) {
    //       console.error("Invalid Local Date:", localDate);
    //       return "Invalid Date"; // Handle invalid date case
    //   }

    //   // Format the local date for display in the UI (without seconds)
    //   const formattedLocalDateTime = format(localDate, 'yyyy-MM-dd HH:mm');
    //   return formattedLocalDateTime;
    // }
  }
}
